button, input[type="button"], input[type="submit"] {
  appearance: none;
  background-color: #E0E0E0;
  border: 0;
  border-radius: 3px;
  color: contrast-switch(#E0E0E0);
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Oswald", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:focus, input[type="button"]:focus, input[type="submit"]:focus {
    outline: none;
    outline-offset: inherit; }
  button:disabled, input[type="button"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #E0E0E0; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  width: 100%; }

[type="checkbox"]:focus,
[type="radio"]:focus,
[type="file"]:focus,
select:focus {
  outline: 3px solid rgba(224, 224, 224, 0.6);
  outline-offset: 2px; }

html {
  background-color: #fff;
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

body {
  margin: 0; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 1.5em 0;
  text-align: left;
  width: 100%; }

thead {
  line-height: 1.2;
  vertical-align: bottom; }

tbody {
  vertical-align: top; }

tr {
  border-bottom: 1px solid #E0E0E0; }

th {
  font-weight: 600; }

th,
td {
  padding: 0.75em 1.5em; }
  @media screen and (max-width: 1400px) {
    th,
    td {
      padding: 0.75em;
      padding-left: 1.5em; } }

/**
 * Retrieves a color value from the $material-colors Sass map
 * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/
 *
 * @function
 * @param {string} color-name             - quoted, lower-case, dasherized color
 *                                          name (e.g. 'pink', 'amber')
 * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.
 *                                          '200', 'a100')
 */
html {
  color: #424242;
  font-family: "Roboto", "Oswald", "Helvetica", "Arial", sans-serif;
  font-size: 100%;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Oswald", "Helvetica", "Arial", sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #2196f3;
  text-decoration-skip: ink;
  transition: color 150ms ease; }
  a:focus {
    outline: none;
    outline-offset: none; }

hr {
  border-bottom: 1px solid #E0E0E0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

[textalign="center"] {
  text-align: center !important; }

[textalign="left"] {
  text-align: left; }

[textalign="right"] {
  text-align: right; }

[textalign="justify"] {
  text-align: justify; }
