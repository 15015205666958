.slider {
	position: relative;
	width: 100%;
	height: 400px;
	overflow: hidden
}

.slider a.previousButton, .slider a.nextButton {
	font-size: 22px;
	line-height: 0;
	display: block;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
	z-index: 1;
	color: #333;
	padding: 10px;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden
	/* prevent jump effect when scaling */
}

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
	-webkit-transform: translateY(-50%) scale(1.25);
	        transform: translateY(-50%) scale(1.25);
	cursor: pointer;
}

.slider a.previousButton {
	left: 20px;
}

.slider a.nextButton {
	right: 20px;
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}

.slide.hidden {
	visibility: hidden;
}

.slide.previous {
	left: -100%;
}

.slide.current {
	left: 0;
}

.slide.next {
	left: 100%;
}

.slide.animateIn,
	.slide.animateOut {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.slide.animateIn.previous,
		.slide.animateIn.next {
	left: 0;
	visibility: visible;
}

.slide.animateOut.previous {
	left: 100%;
}

.slide.animateOut.next {
	left: -100%;
}

.slide h1, .slide h3 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  font-weight: 500
}

.slide.animateIn.previous h1,
.slide.animateIn.previous h3,
.slide.current h1,
.slide.current h3,
.slide.animateIn.next h1,
.slide.animateIn.next h3,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: .9s;
  transition-delay: .9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.slide.animateOut p {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 32px;
  height: 32px;
  transition: all .3s ease;
  cursor: pointer;
  text-align: center;
}

.previousButton svg polygon,
.nextButton svg polygon {
  fill: #fff;
  opacity: .5;
}

.previousButton {
  left: 10px;
}

.previousButton:hover {
  left: 5px;
}

.nextButton {
  right: 10px;
}

.nextButton:hover {
  right: 5px;
}

.slider-content {
  text-align: center;
  height: 440px;
}
.slider-content .degradado {
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(19,19,19,1) 0%, rgba(89,89,89,0.3) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(19,19,19,1)), color-stop(100%, rgba(89,89,89,0.3)));
background: -webkit-linear-gradient(top, rgba(19,19,19,1) 0%, rgba(89,89,89,0.3) 100%);
background: -o-linear-gradient(top, rgba(19,19,19,1) 0%, rgba(89,89,89,0.3) 100%);
background: -ms-linear-gradient(top, rgba(19,19,19,1) 0%, rgba(89,89,89,0.3) 100%);
background: linear-gradient(to bottom, rgba(19,19,19,1) 0%, rgba(89,89,89,0.3) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#131313', endColorstr='#595959', GradientType=0 );
}
.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1,
.slider-content .inner h3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
  font-weight: 300;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

.slider-wrapper{
  position: relative;
  overflow: hidden;
  /* border-radius: 4px; */
}

.slider-wrapper,
.slide {
  height: calc(100vh - 75px);
}

.slider-wrapper.medium,
.slider-wrapper.medium .slide {
  height: 500px;
}

.slider-wrapper.short,
.slider-wrapper.short .slide {
  height: 440px;
}

.slider-content .inner h1 {
  font-size: 32px;
}

.slider-content .inner h3 {
  font-size: 24px;
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
  .custom-nav .slick-dots{
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  .slider-content .inner h3,
  .slider-content .inner h1 {
    font-size: 18px;
    line-height: 24px
  }
  .slider-content .inner {
    padding: 0 40px
  }
}

