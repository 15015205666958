.variable-width .slick-slide p {
  background: blue;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
  width: 90%;
}
.slick-slide {
  transition: transform .3s
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
.sliderTap .slick-slider {
  margin: 0 auto 60px;
}
@media (max-width: 640px){
  .sliderTap .slick-slider {
    margin: 0 auto 24px;
  }
}
.slick-slider {
  margin: 0 auto;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-vertical .slick-slide {
  height: 180px;
}
button.slick-arrow {
  /* background-color: grey !important; */
  width: 40px;
  height: 40px;
  /* padding-top: 5px; */
  z-index: 10;
}
button.slick-arrow:before{
  font-size: 36px;
}
button.slick-arrow:hover,
button.slick-arrow:focus {
  background-color: transparent;
}
.slick-center{
  transform: scale(1.2);
  padding: 0 1%;
}
.thumb-nav .slick-dots {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
  padding: 8px 0 0 0;
  display: flex !important;
  justify-content: flex-start;
  /* justify-content: space-around; */
}
.thumb-nav .slick-dots li:before{
  content: '';
  position: absolute;
  width: 1px;
  height: 72px;
  background-color: #d7d7d5;
  right: 0;
}
.thumb-nav .slick-dots li:last-child::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 72px;
  background-color:transparent;
  right: 0;
}
.thumb-nav .slick-dots li{
  width: 34%;
  /* width: 100%; */
  height: auto;
  min-width: 270px;
  padding-bottom: 8px;
}
.thumb-nav .slick-dots li img{
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.thumb-nav .slick-dots li a.img{
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-right: 5px;
}
.slick-tap{
  display: flex;
  /* justify-content: center; */
}
.flex{
  display: flex;
}
.slick-tap span.content-tap{
  text-align: left !important;
  padding-left: 5px;
}
.slick-tap span.content-tap p{
  height: 43px;
  overflow: hidden;
}
.justify-content{
  display: flex;
  justify-content: space-between;
}
.slider-content section{
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.thumb-nav .slick-dots li.slick-active{
  border-bottom: 2px solid grey;
}
.custom-arrow .nav-prev,
.custom-arrow .nav-next{
  position: absolute;
  bottom: -40px;
  z-index: 3;
}
.custom-arrow .nav-prev{
  left: 0
}
.custom-arrow .nav-next{
  right: 0
}

.custom-arrow .nav-prev1,
.custom-arrow .nav-next1{
  position: absolute;
  top: -58px;
  height: 35px;
  z-index: 3;
  right: 0;
  color: white;
}
.custom-arrow .nav-prev1{
  right: 50px;
  border-radius: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.custom-arrow .nav-next1{
  right: 0;
  border-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.custom-arrow .nav-prev-portadas,
.custom-arrow .nav-next-portadas{
  position: absolute;
  bottom: 70px;
  z-index: 10;
}
.custom-arrow .nav-prev-portadas svg,
.custom-arrow .nav-next-portadas svg{
  font-size: 40px !important;
  /* color: #304fc3; */
}
.custom-arrow .nav-prev-portadas{
  left: -40px
}
.custom-arrow .nav-next-portadas{
  right:-40px
}

.custom-arrow .nav-prev-relevante,
.custom-arrow .nav-next-relevante{
  position: absolute;
  bottom: 145px;
  z-index: 10;
}
.custom-arrow .nav-prev-relevante svg,
.custom-arrow .nav-next-relevante svg{
  font-size: 40px !important;
  color: #ffffff;
}
.custom-arrow .nav-prev-relevante{
  left: 0
}
.custom-arrow .nav-next-relevante{
  right: 0
}
.container{
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
