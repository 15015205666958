*:focus {
    outline: none !important;
}

body {
    overflow-x: hidden;
}

@media (max-width: 425px){
    html {
      font-size: 85% !important;
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}

::-webkit-scrollbar-track {
    background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background-color: #949494;
}

.relative {
    position: relative;
}

.fecha {
    position: absolute;
    top: 75px;
    right: 20px;
    z-index: 1;
}

.marginTopLogo{
    margin-top: 20px
}
.blockCenter{
    display: block;
    text-align: center;
}
.btnActiveDirectory{
    font-family: "Roboto";
    min-width: 200px;
    background-color: #008089;
    text-transform: uppercase;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 8px 16px;
    margin-bottom: 8px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    transition: .2s;
    line-height: 1.4em;
    box-sizing: border-box;
    min-height: 36px;
    display: inline-flex;
    justify-content: center;
    width: 100%;
}
.btnActiveDirectory:hover{
    background-color: #006466;
    transition: .2s;
}
.btnActiveDirectory .iconSmall{
    font-size: 18px;
    margin-left: 8px;
}
.read-more-button{
    display: inline-block;
    cursor: pointer;
    transition: .2s;
    /* color: #adacac; */
}
.read-more-button:hover{
    opacity: .7;
    transition: .2s;
}
.display-text-group{
    cursor: pointer;
}
