@import "../../styles/variables";
@import "../../styles/mixins";

$Footer: 'Footer';

.#{$Footer} {
  display: flex;
  flex-direction: column;
}
.Footer{
  margin: 0 -12px -48px;
}
.sintesisContent {
  text-align: center;
  padding: 8px 12px;
  a {
    font-size: 16px;
    font-weight: 300;
    color: white;
    margin: 0 20px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: .7;
      transition: .3s;
    }
  }
}
// @media (max-width: 360px){
//   .sintesisContent a {
//     display: block;
//   }
// }

.customFooter{
  padding: 30px;
  .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo{
      height: 45px;
      margin-right: 10px;
    }
    .textCopy {
      max-width: 320px;
      text-align: center;
      font-family: sans-serif;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 15px;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      span p{
        strong{
          color: white !important;
        }
        span{
          color: white !important;
        }
      }
    }
  }
}
.subMenu {
  text-align: center;
  padding: 15px 0;
  a { 
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 30px;
    color: white;
    margin: 0 10px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: .7;
      transition: .3s;
    }
  }
  .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 10px;
    // CH 25/06/2019 Se añade por ajuste de doble scroll
    margin-bottom: 20px;
    .logo{
      height: 45px;
      margin-right: 10px;
    }
    .textCopy {
      max-width: 320px;
      text-align: center;
      font-family: sans-serif;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 15px;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
    }
  }
}

.spaceFooterMobil{
  width: 100%;
  height: 100px;
}