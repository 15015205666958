.filters-clasification ul{
    padding-bottom: 9px !important; 
}
.filters-clasification li{
    display: inline-block !important; 
}

/* Filtro producción y ventas */
.checkSearch-input + label::before{
    min-width: 15px !important;
    height: 15px !important;
    border: 2px solid #ffffff !important;
    background-color: transparent !important;
}
.check-label{
    color: #ffffff !important;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Oswald", "Helvetica", "Arial", sans-serif;
    line-height: 1.46429em;
    margin-right: 16px !important;
}
.check-label > span > span:first-child {
    line-height: 25px;
}
.clasification ul li:nth-child(1) .checkSearch-input:checked + label::before{
    background-color: #FF5A1A !important;
}
.clasification ul li:nth-child(2) .checkSearch-input:checked + label::before{
    background-color: #6eb3d1 !important;
}
.clasification ul li:nth-child(2) .count{
    background-color: #6eb3d1 !important;
}

/* AHDA 25/04/2019 */

.clasification-hidden{
    visibility: hidden
}

/* Buscador */
.search-input{
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    height: 40px !important;
}

/* Filtros Laterales -jss.js*/

.heigthSearch{
    padding: 0 !important;
    position: relative;
    display: flex;
    min-height: 40px !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
}
/* .heigthSearch div:first-child > div div:last-child {
    padding: 0 8px 0 20px !important;
} */
.m5 {
    margin: 1rem 0 !important;
}

/* Input Date */
.DayPickerInput input {
    height: 40px !important;
    padding-left: 0 !important;
    background-color: transparent !important;
}
.DayPickerInput-OverlayWrapper{
    margin-left: -50px;
}
.DayPickerInput-OverlayWrapper > div > div > div > div > div{
    min-width: 276px;
}
@media (max-width: 425px) {
    .DayPickerInput-OverlayWrapper{
        margin-left: 0;
    }
    .DayPickerInput-OverlayWrapper > div > div > div > div > div{
        min-width: 230px;
    }
}

.paddingContentSearch{
    padding: 8px;
}
.WrapperButtonSearch{
    display: flex;
}
.WrapperButtonSearch > div + div{
    margin-left: 24px;
}

@media (max-width: 959.5px) {
    .WrapperButtonSearch{
        flex-wrap: wrap;
    }
    .WrapperButtonSearch > div + div{
        margin-left: 0;
        margin-top: 24px;
    }
}